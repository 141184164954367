#A {
    animation: A_c_o 3000ms linear 1 normal forwards
}

@keyframes A_c_o {
    0% {
        opacity: 0
    }
    83% {
        opacity: 0
    }
    100% {
        opacity: 1
    }
}

#G {
    animation: G_c_o 3000ms linear 1 normal forwards
}

@keyframes G_c_o {
    0% {
        opacity: 0
    }
    66% {
        opacity: 0
    }
    100% {
        opacity: 1
    }
}

#T_group {
    animation: T_group__to 3000ms linear 1 normal forwards
}

@keyframes T_group__to {
    0% {
        transform: translate(120.849998px, 65.450001px)
    }
    50% {
        transform: translate(120.849998px, 65.450001px)
    }
    66% {
        transform: translate(199.849998px, 63.450001px)
    }
    100% {
        transform: translate(40.849998px, 67.450001px)
    }
}

#T_group_rotate {
    animation: T_group_rotate__tr 3000ms linear 1 normal forwards
}

@keyframes T_group_rotate__tr {
    0% {
        transform: rotate(0deg)
    }
    50% {
        transform: rotate(0deg)
    }
    100% {
        transform: rotate(360deg)
    }
}

#T_group_scale {
    animation: T_group_scale__ts 3000ms linear 1 normal forwards
}

@keyframes T_group_scale__ts {
    0% {
        transform: scale(1.500000, 1.500000)
    }
    50% {
        transform: scale(1.500000, 1.500000)
    }
    66% {
        transform: scale(1, 1)
    }
    100% {
        transform: scale(1, 1)
    }
}

#T_group_move {
    animation: T_group_move_c_o 3000ms linear 1 normal forwards
}

@keyframes T_group_move_c_o {
    0% {
        opacity: 0
    }
    50% {
        opacity: 1
    }
    100% {
        opacity: 1
    }
}